@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,200&display=swap);
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
*:focus {
	border-color: inherit !important;
	box-shadow: none !important;
}
.text__primary {
	color: #8cc63f !important;
}
.App {
	background-color: #131517;
}
.navbar {
	background-image: url(/static/media/nav-bg.132c5584.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}
.navbar-brand img {
	width: 150px;
	height: 103px;
}
.logo {
	width: 150px;
	height: 103px;
}
.nav-item .nav-link {
	color: white;
	font-size: 20px;
	font-weight: 500;
	font-family: 'Poppins';
}
.btn__connect {
	background: #8cc63f !important;
	border-radius: 20px !important;
	border: none !important;
	padding: 15px 15px;
	font-size: 18px;
	font-weight: 500;
	font-family: 'Poppins';
}
.profile__details {
	padding-top: 20px;
	/* background-image: url('./assets/imgs/1.png'); */
	background-position: right;
	background-repeat: no-repeat;
	background-size: contain;
}
.rise__from {
	font-weight: 600;
	font-family: 'Poppins';
	font-size: 18px;
}
.app__dashboard {
	font-family: 'Poppins';
	font-size: 45px;
	font-weight: 600;
}
.border2 {
	border: 2px solid #8cc63f;
}
.your__profile {
	margin-top: 20px;
	font-family: 'Poppins';
	font-size: 26px;
	font-weight: 600;
}
.card {
	background: #1e1f23 !important;
	border-radius: 41px !important;
	height: 100%;
}
.card-body {
	padding: 30px !important;
}
.icon__img {
	height: 80px !important;
	width: 80px !important;
}
.card__title {
	font-family: 'Poppins';
	font-size: 16px;
	font-weight: 600;
}
.card__desc {
	font-family: 'Poppins';
	font-size: 16px;
	font-weight: 400;
}
.card__numbers {
	font-family: 'Poppins';
	font-size: 30px;
	font-weight: 600;
}
.card__tagline {
	font-family: 'Poppins';
	font-size: 14px;
	font-weight: 400;
}
.form-control {
	border-radius: 26px !important;
	padding: 20px !important;
	font-style: italic;
	font-family: 'Poppins';
	color: black !important;
}
.form-control::-webkit-input-placeholder {
	font-style: italic !important;
	font-family: 'Poppins';
}
.form-control:-ms-input-placeholder {
	font-style: italic !important;
	font-family: 'Poppins';
}
.form-control::placeholder {
	font-style: italic !important;
	font-family: 'Poppins';
}
.table {
	--bs-table-striped-bg: #344128 !important;
	--bs-table-striped-color: #ffffff !important;
	--bs-table-bg: #3f522b !important;
	vertical-align: middle !important;
}
tbody,
td,
tfoot,
th,
thead,
tr {
	border-style: none !important;
	border: 0px !important;
}
.btn__claim {
	background: #8cc63f !important;
	border-radius: 10px !important;
	border: none !important;
	padding: 10px !important;
	font-size: 18px !important;
	font-weight: 700 !important;
	font-family: 'Poppins' !important;
}
.footer {
	background-color: #1e1f23 !important;
	padding: 20px 0px;
}
.custom__contract__card {
	background: #1e1f23 !important;
	border-radius: 41px !important;
	height: 50px !important;
}
.custom__contract__card .card-body {
	padding: 10px 40px 10px 20px !important;
}
.custom__matamask__card {
	background: #8cc63f !important;
	border-radius: 41px !important;
	height: 50px !important;
	margin-left: -30px;
}
.custom__matamask__card .card-body {
	padding: 10px 40px 10px 20px !important;
}
.metamask__img {
	height: 60px;
	margin-left: -20px;
	z-index: 1;
}
@media (max-width: 991.98px) {
	.custom__contract__card {
		height: 40px !important;
		font-size: 14px;
	}
	.custom__matamask__card {
		height: 40px !important;
		font-size: 14px;
		margin-left: -30px;
	}
}
@media (max-width: 767.98px) {
	.custom__contract__card .card-body {
		padding: 10px 15px !important;
	}
	.custom__matamask__card {
		background: #8cc63f !important;
		border-radius: 41px !important;
		height: 40px !important;
		margin-left: 20px;
		width: 100%;
	}
	.custom__matamask__card .card-body {
		padding: 10px 40px 10px 20px !important;
	}
}
@media (max-width: 385.98px) {
	.custom__contract__card {
		font-size: 10px;
	}
}

